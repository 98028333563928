export function dataInJs(e) {
	if(e=='cn'){
		var rows = [{
			name:'深圳市芯航国际电子有限公司（深圳总部）',
			phone:'0755-82056199、82056299、82056399、82056499',
			email:'info@xxintek.com',
			fax:"0755-22922679",
			address:'深圳市罗湖区桂园街道红宝路139号蔡屋围金龙大厦1506-1508'
		},
/*		{
			name:'深圳分公司',
			phone:'0755-82974166、82974566',
			email:'info@xxintek.com',
			fax:null,
			address:'深圳市福田区华强北街道广博现代之窗大厦A座17D'
		},*/
		{
			name:'武汉分公司',
			phone:'027-63497638、63498598、63497068',
			email:'service@xxintek.com',
			fax:"027-63496979",
			address:'武汉市东湖新技术开发区金融港一路7号光谷智慧园1号楼1304室'
		},
		{
			name:'北京分公司',
			phone:'010-86229430',
			email:'info@xxintek.com',
			fax:null,
			address:'北京市房山区篱笆园南路10号院7号楼3层1-302 '
		},
		{
			name:'香港分公司',
			phone:'00852-37052636',
			email:'gerry.chen@xxintek.com',
			fax:"00852-37052637",
			address:'香港新界北区粉岭安全街33号丰盈工贸中心1楼J室'
		}];
	}else{
		var rows = [{
			name:'Xxintek Co., Ltd. (Shenzhen Headquarters)',
			phone:'0755-82056199、 82056299、 82056399、82056499',
			email:'info@xxintek.com',
			fax:"0755-22922679",
			address:'1506-1508, Caiwuwei Jinlong Building, No. 139 Hongbao Road, Guiyuan Street, Luohu District, Shenzhen'
		},
		/*{
			name:'Shenzhen branch office',
			phone:'0755-82974166、82974566',
			email:'info@xxintek.com',
			fax:null,
			address:'17D, Block A, Guangbo Modern Window Building, Huaqiangbei Street, Futian District, Shenzhen'
		},*/
		{
			name:'WUHAN branch',
			phone:'027-63497638  63498598  63497068',
			email:'service@xxintek.com',
			fax:"027-63496979",
			address:'Room 1304, Building 1, Optics Valley Smart Park, No. 7 Financial Port 1st Road, Donghu New Technology Development Zone, Wuhan City'
		},
		{
			name:'Beijing Branch',
			phone:'010-86229430',
			email:'info@xxintek.com',
			fax:null,
			address:'1-302, 3rd Floor, Building 7, No. 10 Fenziyuan South Road, Fangshan District, Beijing'
		},
		{
			name:'Hong Kong Branch',
			phone:'00852-37052636',
			email:'gerry.chen@xxintek.com',
			fax:"00852-37052637",
			address:'Room J, 1st Floor, Fung Ying Industrial and Trade Centre, 33 On An Street, Fanling, North New Territories, Hong Kong'
		}];
	}
	return rows;
}
